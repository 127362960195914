import React, { FunctionComponent, MouseEvent, useState } from "react";
import {
  StyledDefaultButton,
  StyledLinkButton,
  StyledPrimaryButton,
  StyledSecondaryButton,
  StyledSpinner,
} from "./button.styles";
import { useTheme } from "hooks/theme";
import { FADE_OUT_DELAY } from "templates/questionnaire/transition.styles";

export type ButtonType = "primary" | "secondary" | "default" | "link";

export interface ButtonProps {
  className?: string;
  text?: string;
  disabled?: boolean;
  href: string; // add empty string if otherwise not possible to compile href
  type: ButtonType;
  onClick: () => void;
  $loadingStatus?: boolean;
}

const Spinner = () => (
  <StyledSpinner>
    <circle
      className="path"
      cx="25"
      cy="25"
      r="12"
      fill="none"
      strokeWidth="2.5"
    />
  </StyledSpinner>
);

const Button: FunctionComponent<ButtonProps> = ({
  className = "",
  text,
  children,
  onClick,
  disabled = false,
  type = "default",
  href = "",
  $loadingStatus = false,
}) => {
  const [clicked, toggleClicked] = useState(false);
  const theme = useTheme();

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (!disabled) {
      onClick();
    }

    if (theme === "MasterEnglish") {
      // make the button act like it would be in down state
      toggleClicked(true);

      // revert state after some time
      setTimeout(() => toggleClicked(false), FADE_OUT_DELAY * 3);
    }
  };

  const Component = getButtonComponent(type);
  return (
    <Component
      href={href}
      className={`${className} button ${clicked === true ? "down" : ""}`}
      type={type}
      onClick={handleClick}
      $loadingStatus={$loadingStatus}
      $theme={theme}
      $disabled={disabled}
    >
      {$loadingStatus && <Spinner />}
      {text || children}
    </Component>
  );
};

export default Button;

const getButtonComponent = (type: ButtonType) => {
  switch (type) {
    case "primary":
      return StyledPrimaryButton;
    case "secondary":
      return StyledSecondaryButton;
    case "link":
      return StyledLinkButton;
    case "default":
    default:
      return StyledDefaultButton;
  }
};
