import React, { FunctionComponent } from "react";
import Section from "components/layout/section";
import Title from "components/title";
import Description from "components/description";
import Block from "components/layout/block";
import Button from "components/button";
import { useRouter } from "next/router";
import { useTranslations } from "hooks/translations";
import {
  createEmbeddedQuestionnaireState,
  getQuestionnaireUrl,
} from "lib/questionnaire";
import { EmbeddedQuestionnaireVersion } from "models/questionnaire";

interface BuildProgramProps {}

const BuildProgram: FunctionComponent<BuildProgramProps> = () => {
  const router = useRouter();
  const t = useTranslations();
  let url = getQuestionnaireUrl(t, EmbeddedQuestionnaireVersion);

  const handleClick = () => {
    createEmbeddedQuestionnaireState(t, router);
  };

  return (
    <Section>
      <Block>
        <Title mode="h2-with-h1-styles">{t("section_6_title_v28")}</Title>
        <Description>{t("section_6_description_p1_embedded")}</Description>
        <Description>{t("section_6_description_p2_embedded")}</Description>
        <Description>{t("section_6_description_p3_embedded")}</Description>
        <Button
          type="primary"
          onClick={handleClick}
          text={t("section_6_button")}
          href={url}
        ></Button>
      </Block>
    </Section>
  );
};

export default BuildProgram;
