import { getMediaUrl } from "lib/media";
import { useRouter } from "next/router";
import { FunctionComponent } from "react";
import { StyledLogo, StyledLogos } from "./logos.styles";

const Logos: FunctionComponent = () => {
  const router = useRouter();
  return (
    <StyledLogos>
      <StyledLogo
        src={getMediaUrl("home/BreakthroughAward", router, {
          extension: "webp",
        })}
        alt="Breakthrough Award"
      />
      <StyledLogo
        src={getMediaUrl("home/Patent", router, { extension: "webp" })}
        alt="Patent"
      />
    </StyledLogos>
  );
};

export default Logos;
