import { getMediaUrl, MediaUrls } from "lib/media";
import { NextRouter } from "next/router";
import styled, { css } from "styled-components";

export const parseBadgeUrl = (
  router: NextRouter,
  filename: string,
): MediaUrls => {
  const normal = getMediaUrl(`badge/${filename}`, router, {
    extension: "svg",
    size: "1x",
  });

  // no 3x available
  return {
    mobile: normal,
    tablet: normal,
    desktop: normal,
  };
};

export const StyledWrapper = styled.div`
  position: relative;
  z-index: 75;
`;

export const ImageCSSHorizontal = css`
  position: absolute;
  z-index: 50;
  width: 108px;
  height: 108px;
  margin: 0;
  right: 1.25rem;
  top: 1rem;

  animation: entry 1s cubic-bezier(0.83, 0, 0.17, 1) forwards;

  @keyframes entry {
    0% {
      opacity: 0;
      transform: translate(300px, 0);
    }
    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
`;

export const ImageCSSVertical = css`
  position: absolute;
  width: 108px;
  height: 108px;
  margin: 0;
  right: 1.25rem;
  top: 1rem;

  animation: entry 1s cubic-bezier(0.83, 0, 0.17, 1) forwards;

  @keyframes entry {
    0% {
      opacity: 0;
      transform: translate(0, 75px);
    }
    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
`;
