import React, { FunctionComponent } from "react";
import Block from "components/layout/block";
import Section from "components/layout/section";
import Title from "components/title";
import Description from "components/description";
import Background from "components/layout/background";
import Button from "components/button";
import { useRouter } from "next/router";
import { useTranslations } from "hooks/translations";
import {
  createEmbeddedQuestionnaireState,
  getQuestionnaireUrl,
} from "lib/questionnaire";
import { EmbeddedQuestionnaireVersion } from "models/questionnaire";
import { StyledSpecialBlock } from "./lady.styles";

interface LadyProps {}

const Lady: FunctionComponent<LadyProps> = () => {
  const router = useRouter();
  const t = useTranslations();
  const url = getQuestionnaireUrl(t, EmbeddedQuestionnaireVersion);

  const handleClick = () => {
    createEmbeddedQuestionnaireState(t, router);
  };

  return (
    <Section>
      <StyledSpecialBlock order={2}>
        <Background
          align="right"
          mobileImageUrl={"home/LadyMobileHomeBg@2x"}
          tabletImageUrl={"home/LadyTabletBg"}
          desktopImageUrl={"home/LadyDesktopHomeBg"}
        ></Background>
      </StyledSpecialBlock>
      <Block order={1}>
        <Title mode={"h2-with-h1-styles"}>{t("section_1_title")}</Title>
        <Description>{t("section_1_description_p1_embedded")}</Description>
        <Description>{t("section_1_description_p2_embedded")}</Description>
        <Button
          type="primary"
          text={t("section_1_button")}
          onClick={handleClick}
          href={url}
        ></Button>
      </Block>
    </Section>
  );
};

export default Lady;
