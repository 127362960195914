import Button, { ButtonProps } from "components/button";
import { useTheme } from "hooks/theme";
import React, { FunctionComponent } from "react";
import { StyledActions } from "./actions.styles";
import Divider from "components/divider";

type ButtonActionType = {
  type: "button";
  button: ButtonProps;
};

type DividerActionType = {
  type: "divider";
  divider: string;
};

export type ActionType = ButtonActionType | DividerActionType;

interface ActionContainerProps {
  actions: ActionType[];
  extraId?: string;
  className?: string;
}

const ActionContainer: FunctionComponent<ActionContainerProps> = ({
  actions,
  className,
  extraId = "",
}) => {
  const theme = useTheme();
  return (
    <StyledActions className={className} $buttons={true} $theme={theme}>
      {actions.map((action, index) => {
        const { type } = action;
        if (type === "divider") {
          const key = [extraId, index, action.divider].join("-");
          return <Divider key={key} text={action.divider} />;
        } else {
          const key = [extraId, index, action.button.text].join("-");
          return <Button key={key} {...action.button} />;
        }
      })}
    </StyledActions>
  );
};

export default ActionContainer;
