import HomeView from "templates/home/home-view";
import { GetStaticProps, InferGetStaticPropsType } from "next";
import React, { FunctionComponent } from "react";

interface HomePageProps {}

const HomePage: FunctionComponent<
  InferGetStaticPropsType<GetStaticProps<HomePageProps>>
> = () => {
  return <HomeView />;
};

export default HomePage;
