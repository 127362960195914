import Image from "components/image";
import { useRouter } from "next/router";
import React, { FunctionComponent } from "react";
import {
  ImageCSSHorizontal,
  ImageCSSVertical,
  StyledWrapper,
  parseBadgeUrl,
} from "./badge.styles";

export type BadgeDirection = "vertical" | "horizontal";

interface BadgeProps {
  className?: string; // for inheritance
  filename: string;
  direction?: BadgeDirection;
}

const Badge: FunctionComponent<BadgeProps> = ({
  className,
  filename,
  direction = "horizontal",
}) => {
  const router = useRouter();
  const urls = parseBadgeUrl(router, filename);

  return (
    <StyledWrapper className={className}>
      <Image
        urls={urls}
        alt={filename}
        css={direction === "horizontal" ? ImageCSSHorizontal : ImageCSSVertical}
      />
    </StyledWrapper>
  );
};

export default Badge;
