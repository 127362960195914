import React, { FunctionComponent } from "react";
import Section from "components/layout/section";
import Block from "components/layout/block";
import Title from "components/title";
import Logos from "../components/logos";
import { useTranslations } from "hooks/translations";
import List from "components/list";
import { StyledRow } from "./effective.styles";
import Col from "components/layout/col";

interface EffectiveProps {}

const Effective: FunctionComponent<EffectiveProps> = () => {
  const t = useTranslations();

  return (
    <Section>
      <Block>
        <Title mode="h2-with-h1-styles">{t("section_4_title")}</Title>
        <StyledRow>
          <Col>
            <List
              type="number"
              items={[
                {
                  number: 1,
                  text: t("section_4_description_p1_embedded"),
                },
              ]}
            />
          </Col>
          <Col>
            <List
              type="number"
              items={[
                {
                  number: 2,
                  text: t("section_4_description_p2_embedded"),
                },
              ]}
            />
          </Col>
          <Col>
            <List
              type="number"
              items={[
                {
                  number: 3,
                  text: t("section_4_description_p3_embedded"),
                },
              ]}
            />
          </Col>
          <Col>
            <List
              type="number"
              items={[
                {
                  number: 4,
                  text: t("section_4_description_p4_embedded"),
                },
              ]}
            />
          </Col>
        </StyledRow>
        <Logos />
      </Block>
    </Section>
  );
};

export default Effective;
